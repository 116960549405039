import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import {
  Accordion,
  Block2,
  Box,
  Button,
  Container,
  EventTrackingPixel,
  Grid,
  Image,
  Link,
  Page,
  Row2,
  SmolBlock,
  Text,
  Title,
} from 'src/components';
import AetnaFooter from 'src/components/pages/aetna/AetnaFooter';
import paths from 'src/constants/paths';
import urls from 'src/constants/urls';
import { useSessionContext } from 'src/context/SessionContext';
import { trackGoogleConversion } from 'src/lib/event-tracking';
import { locale, LOCALE_ES, $t } from 'src/utils';
import { ReactComponent as AetnaCvsHealthLogoSvg } from './images/aetna-cvs-health-logo.svg';
import goalsImage from './images/benefits/goals@2x.png';
import insuranceImage from './images/benefits/insurance@2x.png';
import taxesEsImage from './images/benefits/taxes@2x.es.png';
import taxesImage from './images/benefits/taxes@2x.png';

const QUERY = graphql`
  query AetnaMember {
    en: allGraphCmsFaq(
      filter: {
        contexts: { in: aetna_faq }
        locale: { eq: en }
        remoteId: { ne: "cm1p7u1da44la07n16ha8z8mk" }
      }
    ) {
      nodes {
        explanation
        title
      }
    }
    es: allGraphCmsFaq(
      filter: {
        contexts: { in: aetna_faq }
        locale: { eq: es }
        remoteId: { ne: "cm1p7u1da44la07n16ha8z8mk" }
      }
    ) {
      nodes {
        explanation
        title
      }
    }
  }
`;

const Hero = styled.header`
  padding: 180px var(--side-margin) 116px;
`;

const HeroInner = styled.header`
  display: flex;
  align-items: center;
  gap: 72px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const SAetnaCvsHealthLogoSvg = styled(AetnaCvsHealthLogoSvg)`
  max-width: 100%;
  height: auto;
`;

const HeroImage = styled(Text)`
  width: 100%;
`;

const BenefitsGrid = styled.div`
  --columns: 3;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(var(--columns), 1fr);

  @media (max-width: 980px) {
    --columns: 1;
    max-width: 384px;
    margin: auto;
  }
`;

const Benefit = styled(Text)`
  background: var(--c-bg-2);
  border-radius: 32px;
  padding: 32px;
`;

const BenefitImage = styled(Image)`
  display: block;
  margin: auto;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%) saturate(0) brightness(500%);
  }

  ${({ $shadow }) =>
    $shadow &&
    css`
      filter: drop-shadow(0 2.89px 23.15px #25272e1a);
    `}
`;

const FaqFooter = styled(Text)`
  color: #ffffffcc;
`;

export default function AetnaMember() {
  const sessionContext = useSessionContext();
  const data = useStaticQuery(QUERY);
  const faq = data[locale].nodes;

  useEffect(() => {
    trackGoogleConversion('aetna0/24_co00y+standard');
  }, []);

  const valueProps = [
    {
      title: $t({
        id: 'aetna-member.value-props.1.title',
        defaultMessage: 'Set goals and start achieving them.',
      }),
      text: $t({
        id: 'aetna-member.value-props.1.text',
        defaultMessage: 'Automate savings for taxes, time off, emergencies and more.',
      }),
    },
    {
      title: $t({
        id: 'aetna-member.value-props.2.title',
        defaultMessage: 'Smart paycheck withholding.',
      }),
      text: $t({
        id: 'aetna-member.value-props.2.text',
        defaultMessage:
          'Each time you’re paid, Catch sets a percentage aside to help you reach your goals.',
      }),
    },
    {
      title: $t({
        id: 'aetna-member.value-props.3.title',
        defaultMessage: 'Adjust as you go.',
      }),
      text: $t({
        id: 'aetna-member.value-props.3.text',
        defaultMessage:
          'Add new goals, change your savings rate, or transfer funds back to your bank in seconds.',
      }),
    },
  ];

  const benefits = [
    {
      title: $t({
        id: 'aetna.benefits.1.title',
        defaultMessage: 'Health coverage',
      }),
      value: 'Active',
      image: insuranceImage,
      imageShadow: true,
    },
    {
      title: $t({
        id: 'aetna.benefits.2.title',
        defaultMessage: 'Taxes',
      }),
      value: '$3,800',
      image: locale === LOCALE_ES ? taxesEsImage : taxesImage,
    },
    {
      title: $t({
        id: 'aetna.benefits.3.title',
        defaultMessage: 'Goals',
      }),
      value: '$6,500',
      image: goalsImage,
    },
  ];

  const title = $t({
    id: 'aetna.title',
    defaultMessage: 'Aetna CVS Health® plan',
  });
  const description = $t({
    id: 'aetna.desctiption',
    defaultMessage:
      'Get more from your Aetna CVS Health® plan, like exclusive access to financial tools built for freelancers from our partner, Catch.',
  });

  const queryParam = '&ask=already_aetna';
  const ctaProps = {
    children: $t({
      id: 'aetna.cta-button',
      defaultMessage: 'Get started',
    }),
    to: sessionContext?.signupUrl
      ? `${sessionContext.signupUrl}${queryParam}`
      : `${urls.SIGN_UP}?${queryParam}`,
  };

  const cta = <Button {...ctaProps} />;

  return (
    <Page
      hideBanner
      hideLinks
      metadata={{
        description,
        title,
      }}
      navBorder={false}
      title={title}
      footer={<AetnaFooter />}
    >
      <EventTrackingPixel cat="24_co00y" type="aetna0" />
      <Helmet>
        <body className="aetna-page" />
      </Helmet>
      <Hero>
        <Container>
          <HeroInner>
            <div>
              <Row2>
                <Row2 size="large">
                  <SAetnaCvsHealthLogoSvg aria-label="Aetna CVS Health®" />
                </Row2>
                <Row2 size="xsmall">
                  <Text element="h1" size="xxxxxlarge">
                    {$t({
                      id: 'aetna-member.hero.title',
                      defaultMessage: 'Tax and savings tools built for you',
                    })}
                  </Text>
                </Row2>
                <Text element="p" flush size="large">
                  {description}
                </Text>
              </Row2>
              {cta}
            </div>
            <HeroImage align="center">
              <StaticImage
                src="./images/heart@2x.png"
                alt=""
                loading="eager"
                placeholder="none"
                width={520}
              />
            </HeroImage>
          </HeroInner>
        </Container>
      </Hero>
      <Block2 color="grey">
        <Container>
          <Title
            subtitle={$t({
              id: 'aetna-member.value-props.subtitle',
              defaultMessage:
                'Freelance fearlessly with free financial tools from Catch, exclusive for Aetna CVS Health members. Whether you’re a photographer, a founder or still figuring it out, we’ve got your back.',
            })}
          >
            {$t({
              id: 'aetna-member.value-props.title',
              defaultMessage: 'Simplify your finances with Catch',
            })}
          </Title>
          <Row2>
            <Grid num={3}>
              {valueProps.map((item, index) => (
                <SmolBlock
                  color="purple"
                  key={item.title}
                  step={++index}
                  text={item.text}
                  title={item.title}
                />
              ))}
            </Grid>
          </Row2>
          <Text align="center" element="footer">
            {cta}
          </Text>
        </Container>
      </Block2>
      <Block2>
        <Container>
          <Text align="center" element="section">
            <Title
              subtitle={$t({
                id: 'aetna-member.benefits.subtitle',
                defaultMessage:
                  'Put aside a percentage of each paycheck each time you’re paid. Aetna CVS Health members get exclusive access to tools from Catch that streamline savings and help you stay on top of your money.',
              })}
            >
              {$t({
                id: 'aetna-member.benefits.title',
                defaultMessage: 'Save for taxes, time off and more',
              })}
            </Title>
            <Row2 size="large">
              <BenefitsGrid>
                {benefits.map((item) => (
                  <Benefit align="left" element="figure" key={item.title}>
                    <Row2 element="figcaption" size="small">
                      <Row2 size="xxxsmall">
                        <Text color="purple" element="h3">
                          {item.title}
                        </Text>
                      </Row2>
                      <Text size="xxlarge">{item.value}</Text>
                    </Row2>
                    <BenefitImage src2x={item.image} $shadow={item.imageShadow} />
                  </Benefit>
                ))}
              </BenefitsGrid>
            </Row2>
            {cta}
          </Text>
        </Container>
      </Block2>
      <Row2 size="large">
        <Box color="black">
          <Container narrow>
            <Row2 size="large">
              <Text element="h2" size="xxxxlarge">
                {$t({
                  id: 'FAQsection.title-1',
                  defaultMessage: 'What’s the catch?',
                })}
                <br />
                <Text color="purple">
                  {$t({
                    id: 'FAQsection.title-2',
                    defaultMessage: 'There isn’t one.',
                  })}
                </Text>
              </Text>
            </Row2>
            <Row2 size="large">
              <div className="dark">
                <Accordion
                  items={faq.map((item) => ({
                    title: item.title,
                    content: item.explanation,
                  }))}
                  showCaret
                />
              </div>
            </Row2>
            <FaqFooter element="p" flush>
              {$t({
                id: 'aetna.faq.guides-cta',
                defaultMessage: 'Something else? See answers to more questions {link}.',
                data: {
                  link: (
                    <Link to={paths.GUIDES_HEALTH} color="white">
                      {$t({
                        id: 'aetna.faq.guides-cta.link',
                        defaultMessage: 'here',
                      })}
                    </Link>
                  ),
                },
              })}
            </FaqFooter>
          </Container>
        </Box>
      </Row2>
      <Block2>
        <Container>
          <Box color="purple">
            <Text align="center" element="div">
              <Title
                subtitle={$t({
                  id: 'aetna-member.cta.subtitle',
                  defaultMessage:
                    'Aetna CVS Health members get free, exclusive access to free financial tools from Catch.',
                })}
              >
                {$t({
                  id: 'aetna-member.cta.title',
                  defaultMessage: 'Start saving today',
                })}
              </Title>
              <Button {...ctaProps} color="white" />
            </Text>
          </Box>
        </Container>
      </Block2>
    </Page>
  );
}
